<template>
  <div class="password-reset">
    <div class="public-screen middle-box animated fadeIn">
      <img class="logo" src="@/assets/images/prologistics_logo.png" alt="Logo"/>
      <h2>{{ $t("password_reset.title") }}</h2>
      <p><strong>{{ $t("password_reset.steps") }}</strong></p>
      <ol>
        <li>{{ $t("password_reset.step1") }}</li>
        <li>{{ $t("password_reset.step2") }}</li>
        <li>{{ $t("password_reset.step3") }}</li>
      </ol>

      <b-form @submit.prevent="onSubmit">
        <generic-input
            label="common.email"
            v-model="email"
            type="email"
            :placeholder="$t('login.email')"
            required
        ></generic-input>

        <b-button class="m-b block full-width" type="submit" variant="primary">
          {{ $t('button.reset_password') }}
        </b-button>
      </b-form>

      <shared-links :showLoginButton="true" class="text-center"/>
    </div>

  </div>
</template>

<script>
import SharedLinks from "@/components/shared_links";
import {sendResetPasswordEmail} from "@/services/employees";
import {EventBus} from "@/eventbus";
import GenericInput from "@/components/generic_input.vue";

export default {
  components: {GenericInput, SharedLinks},
  data() {
    return {
      email: ''
    }
  },
  methods: {
    onSubmit(event) {
      sendResetPasswordEmail(this.email).then(response => {
        EventBus.$emit("button.reset_password", "password.send_instructions")
        this.$router.push({name: 'dashboard'})
      }).catch()
    },
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
.logo {
  width: 15em;
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>